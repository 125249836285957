import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { tokenStorage } from 'api/client/helpers/tokenStorage';
import {
  useCurrentUserLazy,
  useLogout,
  useOnboardingClientIdentityLazy,
} from 'api/requests';
import { ClientInfoEntity, UserEntity } from 'api/types/entity';
import { LoadingBackdrop, LoadingViewWrapper } from 'components';
import { delay } from 'helpers';
import { ROUTES } from 'libs/navigation';
import { LocalStorage } from 'libs/storage';

import { useClientInfo } from './hooks';

interface State {
  user: UserEntity | null;
  client: ClientInfoEntity | null;
}
interface Action {
  reUser: () => void;
  logout: () => void;
}

const Context = createContext<State & Action>(null as never);

export const useUser = () => useContext(Context);

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isOpenLoading, setIsOpenLoading] = useState(false);

  const [client, setClient] = useState<ClientInfoEntity | null>(null);

  const { getCurrentUser, user, loading: userLoading } = useCurrentUserLazy();

  const { getClientIdentity } = useOnboardingClientIdentityLazy();

  const { getClientInfo } = useClientInfo();

  const { logout } = useLogout();

  const reUser = useCallback(() => {
    const token = tokenStorage.getRefreshToken();
    if (token) {
      getCurrentUser().finally(() => setLoading(false));
    }
  }, [getCurrentUser]);

  const handleLogout = useCallback(async () => {
    setIsOpenLoading(true);

    Promise.all([
      delay(1000),
      logout().finally(() => LocalStorage.clear()),
    ]).finally(() => {
      window.location.replace(ROUTES.login.fullPath);
    });
  }, [logout]);

  const values = useMemo(
    () => ({
      user,
      client,
      logout: handleLogout,
      reUser,
    }),
    [handleLogout, reUser, user, client]
  );

  useLayoutEffect(() => {
    const token = tokenStorage.getRefreshToken();
    if (token) {
      getCurrentUser()
        .then(() =>
          getClientIdentity()
            .then((data) => {
              if (data?.id) {
                return getClientInfo(data.id, data.type);
              }
              return undefined;
            })
            .then((clientInfo) => {
              if (clientInfo) {
                setClient(clientInfo);
              }
            })
        )
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [getClientIdentity, getClientInfo, getCurrentUser]);

  return (
    <LoadingViewWrapper
      loading={(!user || !client) && (loading || userLoading)}
    >
      <LoadingBackdrop isOpen={isOpenLoading} />
      <Context.Provider value={values}>{children}</Context.Provider>
    </LoadingViewWrapper>
  );
};
