import { getColor } from 'helpers';
import { Popover, Pressed, Stack } from 'libs/ui';
import { CSSObject, styled } from 'libs/ui/styled';
import { ColorsDark, ColorsLight } from 'libs/ui/theme';

export const UserStyled = styled(Pressed)(({ theme }) => ({
  background: '#D6F4FF',
  width: 44,
  height: 44,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('mobile')]: {
    width: 32,
    height: 32,
  },

  '.clientName': {
    color: '#0079A5',
  },
}));

export const PopoverStyled = styled(Popover)(() => ({
  marginTop: 20,
  [Popover.classes.paper]: {
    minWidth: 292,
    maxWidth: 320,
  },
}));

export const PopoverContentStyled = styled(Stack)(({ theme }) => ({
  '.headerUser-popoverContent-title': {
    padding: 16,

    '.headerUser-popoverContent-name': {
      display: '-webkit-box',
      /* max-width: 200px; */
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textWrap: 'wrap',
    },

    '.headerUser-popoverContent-caption': {
      color: getColor(theme, {
        dark: ColorsDark.gray250,
        light: ColorsLight.gray250,
      }),
    },
  } as CSSObject,

  '.headerUser-popoverContent-action': {
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 16,
    paddingRight: 16,
    transition: theme.transitions.create(['background-color']),
    '&:hover': {
      backgroundColor: getColor(theme, {
        dark: ColorsDark.gray100,
        light: ColorsLight.blue50,
      }),
    },
  },

  '.headerUser-popoverContent-line': {
    backgroundColor: getColor(theme, {
      dark: ColorsDark.gray100,
      light: ColorsLight.gray100,
    }),
    height: 1,
  } as CSSObject,

  '.headerUser-popoverContent-line2': {
    backgroundColor: getColor(theme, {
      dark: ColorsDark.gray50,
      light: ColorsLight.gray50,
    }),
    height: 1,
  } as CSSObject,
}));
